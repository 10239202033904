import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 600,
  },
  media: {
    height: 800,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#00AFE9",
  },
});

function StepTwo({ formik }) {
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const Options = [
    "Master Bedroom Downstairs",
    "Guest Bedroom Downstairs",
    "Children’s Activity Area",
    "Study / Home Office",
    "Large Alfresco",
    "Multiple Living Areas",
    "Scullery/Butler’s Pantry",
    "Home Theatre",
    "Formal Living or Dining",
    "Master Walk-in-Robe",
    "Walk-in-Robes to Other Bedrooms",
    "Charging Station",
    "Laundry Chute",
    "Under Stair Storage",
  ];

  return (
    <>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            What type of home are you looking to build?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="What type of home are you looking to build?"
            id="home_type"
            name="home_type"
            value={formik.values.home_type}
            onChange={formik.handleChange}
            error={formik.touched.home_type && Boolean(formik.errors.home_type)}
            helperText={formik.touched.home_type && formik.errors.home_type}
          >
            <MenuItem value={"Single Storey"}>Single Storey</MenuItem>
            <MenuItem value={"Double Storey"}>Double Storey</MenuItem>
            <MenuItem value={"Dual Living"}>Dual Living</MenuItem>
            <MenuItem value={"Ranch"}>Ranch/Homestead</MenuItem>
            <MenuItem value={"Split Level"}>Split Level</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            What size home are to looking to build?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="What size home are to looking to build?"
            id="home_size"
            name="home_size"
            value={formik.values.home_size}
            onChange={formik.handleChange}
            error={formik.touched.home_size && Boolean(formik.errors.home_size)}
            helperText={formik.touched.home_size && formik.errors.home_size}
          >
            <MenuItem value={"100m2 to 200m2 (15sq – 22sq)"}>
              100m2 to 200m2 (15sq – 22sq)
            </MenuItem>
            <MenuItem value={"200m2 to 300m2 (22sq – 32sq)"}>
              200m2 to 300m2 (22sq – 32sq)
            </MenuItem>
            <MenuItem value={"300m2 to 400m2 (32sq – 43sq)"}>
              300m2 to 400m2 (32sq – 43sq)
            </MenuItem>
            <MenuItem value={"400m2 to 500m2 (43sq – 53sq)"}>400m2 to 500m2 (43sq – 53sq)</MenuItem>
            <MenuItem value={"500m2 to 600m2 (53sq - 64sq)"}>
              500m2 to 600m2 (53sq - 64sq)
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            How many bedrooms would you like?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="How many bedrooms would you like?"
            id="bedrooms"
            name="bedrooms"
            value={formik.values.bedrooms}
            onChange={formik.handleChange}
            error={formik.touched.bedrooms && Boolean(formik.errors.bedrooms)}
            helperText={formik.touched.bedrooms && formik.errors.bedrooms}
          >
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
            <MenuItem value={"6"}>6</MenuItem>
            <MenuItem value={"7"}>7</MenuItem>
            <MenuItem value={"8"}>8</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            How many bathrooms would you like?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="How many bathrooms would you like?"
            id="bathrooms"
            name="bathrooms"
            value={formik.values.bathrooms}
            onChange={formik.handleChange}
            error={formik.touched.bathrooms && Boolean(formik.errors.bathrooms)}
            helperText={formik.touched.bathrooms && formik.errors.bathrooms}
          >
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"1.5"}>1.5</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"2.5"}>2.5</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"3.5"}>2.5</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"4.5"}>4.5</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            How many parking spaces would you like?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="How many parking spaces would you like?"
            id="parking_spaces"
            name="parking_spaces"
            value={formik.values.parking_spaces}
            // onChange={formik.handleChange}
            error={formik.touched.parking_spaces && Boolean(formik.errors.parking_spaces)}
            helperText={formik.touched.parking_spaces && formik.errors.parking_spaces}
            onChange={formik.handleChange}
          >
            <MenuItem value={"1"}>Single garage</MenuItem>
            <MenuItem value={"2"}>Double garage</MenuItem>
            <MenuItem value={"3"}>Triple garage</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="OptionsLabel">
            What specific home features are your ‘must-have’s’?
          </InputLabel>
          <Select
            labelId="OptionsLabel"
            label="What specific home features are your ‘must-have’s’?"
            id="home_features"
            color="primary"
            multiple
            name="home_features"
            // value={formik.values.home_features}
            value={[]}
            onChange={formik.handleChange}
            error={formik.touched.home_features && Boolean(formik.errors.home_features)}
            helperText={formik.touched.home_features && formik.errors.home_features}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {Options.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}
    </>
  );
}

export default StepTwo;
