import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
    Journey: "", 
    Options: [],
    ReviewDesign: null
  }

export const discoverySlice = createSlice({
    name: 'discovery',
    initialState,
    reducers: {
      UpdateDiscovery: (state, action) => {
        state.Journey = action.payload
      },
      UpdateDate: (state, action) => {
        state.RegisteredDate = action.payload;
      },
      UpdateOptions: (state, action) => {
        state.Options = action.payload;
      },
      UpdateReviewDesign: (state, action) => {
        state.ReviewDesign = action.payload;
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { UpdateDiscovery, UpdateDate, UpdateOptions, UpdateReviewDesign } = discoverySlice.actions
  
  export default discoverySlice.reducer
  