import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    ProgressBar: 0,
    Page: 1,
  }

export const controlSlice = createSlice({
    name: 'controls',
    initialState,
    reducers: {
      NextPage: (state) => {
        state.Page++;
      },
      BackPage: (state) => {
        state.Page--;
      },
      UpdateProgress: (state, action) => {
        state.ProgressBar += action.payload;
      },
      DecrementProgress: (state, action) => {
        state.ProgressBar -= action.payload;
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { NextPage, BackPage, UpdateProgress, DecrementProgress } = controlSlice.actions
  
  export default controlSlice.reducer
  