import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateDate } from "../discoverySlice";

import {
  FormControl,
  Select,
  TextField,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

function StepOne({ formik }) {
  const dispatch = useDispatch();

  const Discovery = useSelector((state) => state.discovery);

  return (
    <>
      <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
        <FormControl fullWidth size="small">
          <Select
            labelId="InitialQuestion"
            variant="outlined"
            id="journey"
            name="journey"
            value={formik.values.journey}
            onChange={formik.handleChange}
            error={formik.touched.journey && Boolean(formik.errors.journey)}
            helperText={formik.touched.journey && formik.errors.journey}
          >
            <MenuItem value={"RegisteredLand"}>
              I have a registered block of land and am looking for a builder
            </MenuItem>
            <MenuItem value={"UnregisteredLand"}>
              I have an unregistered block of land and am looking for a builder
            </MenuItem>
            <MenuItem value={"KDR"}>
              I have an existing home and am looking to knock-down and rebuild
            </MenuItem>
            <MenuItem value={"HL"}>
              I do not have any land and am looking for a home and land package
            </MenuItem>
            <MenuItem value={"Invest"}>I am looking to invest</MenuItem>
            <MenuItem value={"Learn"}>
              I would like to learn more about Hudson Homes
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* IF: I have a registered block of land and am looking for a builder  */}
      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" || formik.values.journey === "HL" || formik.values.journey === "Invest" ? (
        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            fullWidth
            id="suburb"
            name="suburb"
            label={
              formik.values.journey === "RegisteredLand" || formik.values.journey === "UnregisteredLand"
                ? "What Suburb is your block of land located in?"
                : "What suburb/region are you looking to build your new home?"
            }
            variant="outlined"
            value={formik.values.suburb}
            onChange={formik.handleChange}
            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            helperText={formik.touched.suburb && formik.errors.suburb}
          />
        </Grid>
      ) : null}

      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" ? (
        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            fullWidth
            id="estate"
            name="estate"
            label="What estate is your land in?"
            variant="outlined"
            value={formik.values.estate}
            onChange={formik.handleChange}
            error={formik.touched.estate && Boolean(formik.errors.estate)}
            helperText={formik.touched.estate && formik.errors.estate}
          />
        </Grid>
      ) : null}
      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" ||
      formik.values.journey === "KDR" ? (
        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            fullWidth
            id="site_address"
            name="site_address"
            label={
              formik.values.journey === "RegisteredLand" || formik.values.journey === "UnregisteredLand"
                ? "What is the address / lot number of your land??"
                : "What is the address of the home you’re looking to knock-down?"
            }
            variant="outlined"
            value={formik.values.site_address}
            onChange={formik.handleChange}
          />
        </Grid>
      ) : null}
      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" ||
      formik.values.journey === "KDR" ? (
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id="what_is_the_size_of_your_land_"
            name="what_is_the_size_of_your_land_"
            label="What is the size of your land?"
            variant="outlined"
            value={formik.values.what_is_the_size_of_your_land_}
            onChange={formik.handleChange}
            error={formik.touched.what_is_the_size_of_your_land_ && Boolean(formik.errors.what_is_the_size_of_your_land_)}
            helperText={formik.touched.what_is_the_size_of_your_land_ && formik.errors.what_is_the_size_of_your_land_}
          />
        </Grid>
      ) : null}
      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" ||
      formik.values.journey === "KDR" ? (
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id="what_is_the_width_of_your_land_"
            name="what_is_the_width_of_your_land_"
            label="What is the width of your land?"
            variant="outlined"
            value={formik.values.what_is_the_width_of_your_land_}
            onChange={formik.handleChange}
            error={formik.touched.what_is_the_width_of_your_land_ && Boolean(formik.errors.what_is_the_width_of_your_land_)}
            helperText={formik.touched.what_is_the_width_of_your_land_ && formik.errors.what_is_the_width_of_your_land_}
          />
        </Grid>
      ) : null}
      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" ? (
        <Grid item xs={12} md={12}>
          <DatePicker
            size="small"
            label={
              formik.values.journey === "RegisteredLand"
                ? "When was your land registered?"
                : "When is your land due to register?"
            }
            id="when_was_your_land_registered_"
            name="when_was_your_land_registered_"
            value={formik.values.when_was_your_land_registered_}
            onChange={(value) => {
              formik.setFieldValue('when_was_your_land_registered_', Date.parse(value));
              }}
            error={formik.touched.when_was_your_land_registered_ && Boolean(formik.errors.when_was_your_land_registered_)}
            helperText={formik.touched.when_was_your_land_registered_ && formik.errors.when_was_your_land_registered_}
            inputVariant="outlined"
            fullWidth
          />
        </Grid>
      ) : null}
      {formik.values.journey === "RegisteredLand" ||
      formik.values.journey === "UnregisteredLand" ||
      formik.values.journey === "Invest" ||
      formik.values.journey === "HL" ||
      formik.values.journey === "KDR" 
       ? (
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            size="small"
            id="budget"
            name="budget"
            label="What is your Budget?"
            variant="outlined"
            value={formik.values.budget}
            onChange={formik.handleChange}
            error={formik.touched.budget && Boolean(formik.errors.budget)}
            helperText={formik.touched.budget && formik.errors.budget}
          />
        </Grid>
      ) : null}
    </>
  );
}

export default StepOne;
