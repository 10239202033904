import { configureStore } from '@reduxjs/toolkit'
import discoveryReducer from './discoverySlice'
import controlReducer from './controlSlice'

export const store = configureStore({
  reducer: {
    discovery: discoveryReducer,
    control: controlReducer
  },
})
