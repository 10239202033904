import { useState } from "react";
import "./App.css";
import {
  FormControl,
  InputLabel,
  Select,
  Grid,
  Container,
  MenuItem,
  LinearProgress,
  Button,
  TextField,
} from "@material-ui/core";
// import CloudUploadIcon from "@material-ui/icons/Save";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import Swal from 'sweetalert2'

// Hooks
// import { useWindowSize } from "./hooks/useWindowSize";

// Components
import Heading from "./Steps/Heading";
import StepOne from "./Steps/StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

import { useSelector, useDispatch } from "react-redux";
import {
  NextPage,
  BackPage,
  UpdateProgress,
  DecrementProgress,
} from "./controlSlice";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter a phone number")
    .required("Phone Number is required"),
  firstname: yup
  .string("Enter a valid name")
  .required("Name is required"),
  state_new: yup
  .string("Please select a state")
  .required("State is required")
});

// Component
function App() {
  const formik = useFormik({
    initialValues: {
      journey: "",
      email: "",
      phone: "",
      firstname: "",
      timeframe: "",
      has_finance_: "",
      address: "",
      secondary_name: "",
      secondary_email: "",
      secondary_number: "",
      relationship: "",
      comments: "",
      when_was_your_land_registered_: new Date(),
      // home_features: [],
      home_size: "",
      home_type: "",
      bedrooms: "",
      bathrooms: "",
      parking_spaces: "",
      selected_design: "",
      what_hudson_home_design_have_you_selected_: "",
      budget: "",
      suburb: "",
      estate: "",
      site_address: "",
      what_is_the_size_of_your_land_: "",
      what_is_the_width_of_your_land_: "",
      state_new: "",
      what_suburb_region_are_you_looking_to_build_your_new_home_: "",
      region__qld_: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));

      let FormData = Object.entries(values).map(([name, value]) => ({
        name,
        value,
      }));

      // console.log(FormData);

      var data = {
        fields: FormData,
        legalConsentOptions: {
          // Include this object when GDPR options are enabled
          consent: {
            consentToProcess: true,
            text: "I agree to allow Example Company to store and process my personal data.",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "I agree to receive marketing communications from Example Company.",
              },
            ],
          },
        },
      };

      console.log(data);

      var final_data = JSON.stringify(data);

      axios
        .post(
          `https://api.hsforms.com/submissions/v3/integration/submit/20550739/fa333e93-3869-4e9d-84f0-b224b19e2f22`,
          final_data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const data = res.data;
          // console.log(data);

          Swal.fire({
            title: 'Success!',
            text: 'Sales Discovery has been Submitted',
            icon: 'success',
          })

        })
        .catch(function (error) {
          console.log(error.response.data.errors[0].message);

          if(error.response.data.errors[0].message == "Error in 'fields.email'. Invalid email address"){
            Swal.fire({
              title: 'Invalid Email',
              text: 'Please enter a valid email address',
              icon: 'error',
              animation: false
            })
          }else{
            Swal.fire({
              title: 'Error!',
              text: 'Sales Discovery could not be Submitted',
              icon: 'error',
              animation: false
            })
          }

        });
    },
  });

  const dispatch = useDispatch();
  const Controls = useSelector((state) => state.control);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // useEffect(() => {
  //   setWindowDimensions(getWindowDimensions());
  // }, [useWindowSize()])

  return (
    <div>
      <Container style={{ paddingTop: 45 }}>
        {windowDimensions.width < 800 ? (
          <div style={{ textAlign: "center" }}>
            <img src="./HudsonHomes.jpeg" alt="HudsonHomes" width={250} />
          </div>
        ) : null}

        <Grid container spacing={3}>
          <Grid
            item
            xs={windowDimensions.width > 800 ? 6 : 12}
            md={windowDimensions.width > 800 ? 6 : 12}
          >
            <div style={{ textAlign: "center" }}>
              <img src="./SalesDiscovery.jpeg" alt="Discovery" width="90%" />
            </div>
          </Grid>

          <Grid
            item
            xs={windowDimensions.width > 800 ? 6 : 12}
            md={windowDimensions.width > 800 ? 6 : 12}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                style={
                  windowDimensions.width > 800
                    ? { paddingBottom: 100, paddingTop: 45 }
                    : { paddingBottom: 100 }
                }
              >
                {Controls.Page === 1 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Heading Title="How can we assist you with your home building journey?" />
                    </div>
                    <StepOne formik={formik} />
                  </>
                ) : null}
                {/* IF: I would like to know more about Hudson Homes 
        Auto Email with Brochures and Video
        End (except for Customer Details)
        */}

                {Controls.Page === 2 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Heading Title="Have you had the chance to review and select a Hudson Homes design?" />
                    </div>
                    {formik.values.journey !== "" ? (
                      <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
                        <FormControl fullWidth size="small" variant="outlined">
                          <InputLabel id="InitialQuestion">
                            Have you selected a Hudson Homes design?
                          </InputLabel>
                          <Select
                            labelId="InitialQuestion"
                            label="Have you selected a Hudson Homes design?"
                            id="selected_design"
                            name="selected_design"
                            onChange={formik.handleChange}
                            value={formik.values.selected_design}
                            error={
                              formik.touched.selected_design &&
                              Boolean(formik.errors.selected_design)
                            }
                          >
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}
                    {formik.values.selected_design !== "" &&
                    formik.values.selected_design !== "No" ? (
                      <>
                        <Grid item xs={12} md={12}>
                          <TextField
                            size="small"
                            fullWidth
                            id="what_hudson_home_design_have_you_selected_"
                            name="what_hudson_home_design_have_you_selected_"
                            label="What Hudson Home Design have you selected?"
                            variant="outlined"
                            value={
                              formik.values
                                .what_hudson_home_design_have_you_selected_
                            }
                            onChange={formik.handleChange}
                            error={
                              formik.touched
                                .what_hudson_home_design_have_you_selected_ &&
                              Boolean(
                                formik.errors
                                  .what_hudson_home_design_have_you_selected_
                              )
                            }
                          />
                        </Grid>
                      </>
                    ) : null}
                    <StepTwo formik={formik} />
                  </>
                ) : null}

                {Controls.Page === 3 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Heading Title="Your Details" />
                    </div>
                    <StepThree formik={formik} />
                  </>
                ) : null}

                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "left", marginTop: 75 }}
                    disabled={Controls.Page > 1 ? false : true}
                    onClick={() => {
                      dispatch(BackPage(1), dispatch(DecrementProgress(33.3)));
                    }}
                  >
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right", marginTop: 75 }}
                    type="submit"
                    disabled={Controls.Page !== 3 ? true : false}
                  >
                    Finish
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right", marginTop: 75, marginRight: 10 }}
                    disabled={
                       formik.values.journey === "" || Controls.Page === 3
                        ? true
                        : false
                    }
                    onClick={() => {
                      dispatch(NextPage(1), dispatch(UpdateProgress(33.3)));
                    }}
                  >
                    Next
                  </Button>

                  <LinearProgress
                    variant="determinate"
                    value={Controls.ProgressBar}
                    style={{ marginTop: 150, height: 20 }}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
