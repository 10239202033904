import React from "react";
import { Typography } from "@material-ui/core";

function Heading({ Title }) {
  return (
    <>
      <Typography
        variant="h6"
        style={{
          color: "#00AFE9",
          fontFamily: "OpenSansSemiBold",
          fontSize: 18,
          paddingTop: 15,
        }}
      >
        {Title}
      </Typography>
    </>
  );
}

export default Heading;
