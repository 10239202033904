import React from "react";

import {
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

function StepThree({ formik }) {
  return (
    <>
      <Grid item xs={12} md={12} style={{ marginTop: 10 }}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            When are you looking to start your home building journey?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="When are you looking to start your home building journey?"
            id="timeframe"
            name="timeframe"
            value={formik.values.timeframe}
            onChange={formik.handleChange}
            error={formik.touched.timeframe && Boolean(formik.errors.timeframe)}
            helperText={formik.touched.timeframe && formik.errors.timeframe}
          >
            <MenuItem value={"3 Months"}>Within 3 Months</MenuItem>
            <MenuItem value={"6 Months"}>6 Months</MenuItem>
            <MenuItem value={"12 Months"}>12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="InitialQuestion">
            Do you need assistance with Finance?
          </InputLabel>
          <Select
            labelId="InitialQuestion"
            label="Do you need assistance with Finance?"
            id="has_finance_"
            name="has_finance_"
            value={formik.values.has_finance_}
            onChange={formik.handleChange}
            error={
              formik.touched.has_finance_ && Boolean(formik.errors.has_finance_)
            }
            helperText={
              formik.touched.has_finance_ && formik.errors.has_finance_
            }
          >
            <MenuItem value={"Yes"}>
              Yes! Please refer me to a Finance professional.
            </MenuItem>
            <MenuItem value={"No"}>
              No. I have spoken to a Finance professional and have my Finance
              approval
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="firstname"
          name="firstname"
          label="Full Name*"
          variant="outlined"
          value={formik.values.firstname}
          onChange={formik.handleChange}
          error={formik.touched.firstname && Boolean(formik.errors.firstname)}
          helperText={formik.touched.firstname && formik.errors.firstname}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="address"
          name="address"
          label="Current Address"
          variant="outlined"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="state_new">What is your State?</InputLabel>
          <Select
            labelId="state_new"
            label="What is your State?"
            id="state_new"
            name="state_new"
            value={formik.values.state_new}
            // onChange={formik.handleChange}
            error={formik.touched.state_new && Boolean(formik.errors.state_new)}
            helperText={formik.touched.stastate_newte && formik.errors.state_new}
            onChange={formik.handleChange}
          >
            <MenuItem value={"NSW"}>NSW</MenuItem>
            <MenuItem value={"QLD"}>QLD</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {formik.values.state_new === "NSW" ? (
        <Grid item xs={12} md={12}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="what_suburb_region_are_you_looking_to_build_your_new_home_">
              What Region Are you in?
            </InputLabel>
            <Select
              labelId="what_suburb_region_are_you_looking_to_build_your_new_home_"
              label="What Region Are you in?"
              id="what_suburb_region_are_you_looking_to_build_your_new_home_"
              name="what_suburb_region_are_you_looking_to_build_your_new_home_"
              value={formik.values.what_suburb_region_are_you_looking_to_build_your_new_home_}
              // onChange={formik.handleChange}
              error={
                formik.touched.what_suburb_region_are_you_looking_to_build_your_new_home_ && Boolean(formik.errors.what_suburb_region_are_you_looking_to_build_your_new_home_)
              }
              helperText={formik.touched.what_suburb_region_are_you_looking_to_build_your_new_home_ && formik.errors.what_suburb_region_are_you_looking_to_build_your_new_home_}
              onChange={formik.handleChange}
            >
              <MenuItem value={"Lower Hunter"}>Lower Hunter</MenuItem>
              <MenuItem value={"Central Coast"}>Central Coast</MenuItem>
              <MenuItem value={"South-West Sydney"}>South-West Sydney</MenuItem>
              <MenuItem value={"North-West Sydney"}>North-West Sydney</MenuItem>
              <MenuItem value={"Greater Western Sydney"}>Greater Western Sydney</MenuItem>
              <MenuItem value={"Western Sydney"}>Western Sydney</MenuItem>
              <MenuItem value={"Northern Sydney"}>Northern Sydney</MenuItem>
              <MenuItem value={"NSW Regional Other"}>NSW Regional Other</MenuItem>
              <MenuItem value={"Sydney Other"}>Sydney Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : null}
    {formik.values.state_new === "QLD" ? (
      <Grid item xs={12} md={12}>
        <FormControl fullWidth size="small" variant="outlined">
          <InputLabel id="region__qld_">What Region Are you in?</InputLabel>
          <Select
            labelId="region__qld_"
            label="What Region Are you in?"
            id="region__qld_"
            name="region__qld_"
            value={formik.values.region__qld_}
            // onChange={formik.handleChange}
            error={
              formik.touched.region__qld_ && Boolean(formik.errors.region__qld_)
            }
            helperText={formik.touched.region__qld_ && formik.errors.region__qld_}
            onChange={formik.handleChange}
          >
            <MenuItem value={"Brisbane"}>Brisbane</MenuItem>
            <MenuItem value={"Gold Coast"}>Gold Coast</MenuItem>
            <MenuItem value={"Ipswich Region"}>Ipswich Region</MenuItem>
            <MenuItem value={"QLD Other"}>QLD Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    ) : null}
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="email"
          name="email"
          label="Email*"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="phone"
          name="phone"
          label="Phone Number*"
          variant="outlined"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="secondary_name"
          name="secondary_name"
          label="Secondary Name"
          variant="outlined"
          value={formik.values.secondary_name}
          onChange={formik.handleChange}
          error={
            formik.touched.secondary_name &&
            Boolean(formik.errors.secondary_name)
          }
          helperText={
            formik.touched.secondary_name && formik.errors.secondary_name
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="secondary_email"
          name="secondary_email"
          label="Secondary Email"
          variant="outlined"
          value={formik.values.secondary_email}
          onChange={formik.handleChange}
          error={
            formik.touched.secondary_email &&
            Boolean(formik.errors.secondary_email)
          }
          helperText={
            formik.touched.secondary_email && formik.errors.secondary_email
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="secondary_number"
          name="secondary_number"
          label="Secondary Phone Number"
          variant="outlined"
          value={formik.values.secondary_number}
          onChange={formik.handleChange}
          error={
            formik.touched.secondary_number &&
            Boolean(formik.errors.secondary_number)
          }
          helperText={
            formik.touched.secondary_number && formik.errors.secondary_number
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          size="small"
          fullWidth
          id="relationship"
          name="relationship"
          label="Relationship to you"
          variant="outlined"
          value={formik.values.relationship}
          onChange={formik.handleChange}
          error={
            formik.touched.relationship && Boolean(formik.errors.relationship)
          }
          helperText={formik.touched.relationship && formik.errors.relationship}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          multiline
          rows={5}
          id="comments"
          name="comments"
          label="Comments"
          variant="outlined"
          value={formik.values.comments}
          onChange={formik.handleChange}
          error={formik.touched.comments && Boolean(formik.errors.comments)}
          helperText={formik.touched.comments && formik.errors.comments}
        />
      </Grid>
    </>
  );
}

export default StepThree;
